import { atom } from 'recoil';
import { IOrderMetadata } from '../../Page/OrderSummary/ReceiptForm';
import { PaymentMethodEnum } from 'Constants/common';

export interface ICompanyInfo {
    companyName: string;
    companyAccount: string;
    bankCode: string;
}

export interface IOrder {
    createdAt: Date;
    purchaseOrderRefId: string;
    orderStatus: number;
    paidExpireAt: Date;
    paidStatus: number;
    purchaseOrderProduct: IPurchaseOrderProduct;
    purchaseOrderProgress: IPurchaseOrderProgress;
    purchaseOrderReceipt: IPurchaseOrderReceipt;
    companyInfo: ICompanyInfo;
}

export interface IOrderPaymentPromptPay {
    transactionId: string;
    qrcodeUrl?: string;
    paidExpireAt: string;
    companyName: string;
    companyAccount: string;
    bankCode: string;
    refId: string;
    productPrice: string;
    totalPrice: string;
}

export interface IOrderSummary {
    purchaseOrderProduct: IPurchaseOrderProduct;
    purchaseOrderProgress: IPurchaseOrderProgress;
}

export interface IPurchaseOrderProduct {
    accountType: number;
    amount: number;
    platformName: string;
    price: number;
    serviceName: string;
    productItemFullCode?: string;
}

export interface IPurchaseOrderProgress {
    progressCount: number;
    progressTotal: number;
    link: string;
    links?: string[];
    socialAccountName: string;
    metadata?: IOrderMetadata;
    serviceOption?: string | undefined;
}

export interface IPurchaseOrderReceipt {
    transactionPrice: number;
    couponType: number;
    couponValue: number;
    discountPrice: number;
    paymentMethod: PaymentMethodEnum;
}

export const OrderSingleAtom = atom({
    key: 'order_single',
    default: undefined as IOrder | undefined,
});

export const OrderPaymentPromptPayAtom = atom({
    key: 'order_payment_prompt',
    default: undefined as IOrderPaymentPromptPay | undefined,
});

export const OrderHistoryAtom = atom({
    key: 'order_history',
    default: [] as [] | IOrder[],
});
