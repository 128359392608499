import React, { useEffect, useState } from 'react';
import { useTrans } from '@core/Hook/trans';
import { Asset } from '@core/Utils/image';
import { useResponsive } from '@core/Hook/responsiveHook';
import OrderCardLayout from '../Component/Order/OrderCardLayout';
import Layout from '../Component/Common/Layout';
import MetaSeo from '../Component/Common/MetaSeo';
import PaymentGroup, { IPaymentChoice } from './PaymentMethod/PaymentGroup';
import PlainButton from '../Component/Button/PlainButton';
import IconWowBoostCredit from '../Page/PaymentMethod/icon-wowboost-credit.png';
import IconBankTransfer from '../Page/PaymentMethod/icon-bank-transfer.png';
import IconScanQr from '../Page/PaymentMethod/icon-qr-code.png';
import IconCreditCard from '../Page/PaymentMethod/icon-credit.png';
import IconVisa from '../Page/PaymentMethod/logo-visa.png';
import IconMasterCard from '../Page/PaymentMethod/logo-mastercard.png';
import IconBank from '../Page/PaymentMethod/logo-bank.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { resolveClientApiState, useClientApiState } from '@core/Hook/api';
import PaymentProcessModal from './OrderSummary/PaymentProcessModal';
import PaymentCreditFailModal from './OrderSummary/PaymentCreditFailModal';
import { useHandleDialog } from '../Hook/modalDialogHook';
import { checkout, checkoutCredit } from '../Api/payment';
import { useManageProductInfo } from '../Hook/product';
import isUndefined from 'lodash/isUndefined';
import usePaymentHook from '../Hook/paymentHook';
import { PaymentMethodEnum } from '../Constants/common';
import { Stack } from '@mui/material';
import useCreditHook from '../Hook/creditHook';
import Box from '@mui/material/Box';
import OrderSummary from '../Component/Order/OrderSummary';
import TextWithLink from '../Component/TextWithLink';
import RefundPolicyModal from './OrderSummary/RefundPolicyModal';
import { CustomerInfoAtom, ICustomerInfo } from '../Recoil/Common/atom';
import { useRecoilState } from 'recoil';
import { OrderTypeEnum } from '../Constants/order';
import { getUserCredit } from '../Api/credit';

interface IOrderInfo {
    paymentMethod: string;
    data: string;
    customerInfo: string;
}

const CreditPayment: React.FC = () => {
    const { trans } = useTrans();
    const location = useLocation();
    const navigate = useNavigate();
    const { isTabletOrMobile } = useResponsive();
    const orderType = (location.state.orderType as OrderTypeEnum) || OrderTypeEnum.PRODUCT;
    const orderInfo = location.state.orderInfo;
    const orderCreditInfo = location.state.orderCreditInfo;
    const orderSummaryPrice = location.state.price;
    const [apiCheckoutState, setApiCheckoutState] = useClientApiState({
        isLoading: false,
    });

    const paymentProcessModal = useHandleDialog();
    const paymentCreditFailModal = useHandleDialog();
    const refundPolicyModal = useHandleDialog();
    const { clearProductInfo } = useManageProductInfo();
    const { saveOrderPaymentPromptPay } = usePaymentHook();
    const [lastPurchaseOrderRefId, setLastPurchaseOrderRefId] = useState<string | undefined>(undefined);
    const { useFetchUserCredit, creditValue } = useCreditHook();
    const [apiState, setApiState] = useClientApiState({ isLoading: false });
    const { fetchUserCredit } = useFetchUserCredit(apiState, setApiState);
    const [customerInfo, setCustomerInfo] = useRecoilState(CustomerInfoAtom);

    const isMultipleLinks = location.state.isMultipleLink;
    const bgImage = isTabletOrMobile ? Asset('wow-boost-history-purchase-bg-mobile.jpg') : Asset('wow-boost-history-purchase-bg.jpg');

    useEffect(() => {
        fetchUserCredit();
    }, []);

    const paymentOption = [
        {
            code: PaymentMethodEnum.BANK_TRANSFER,
            label: 'โอนเงินผ่านธนาคาร',
            iconDetail: [],
            icon: IconBankTransfer,
        },
        {
            code: PaymentMethodEnum.PROMPTPAY,
            label: 'Scan QR Code',
            iconDetail: [],
            icon: IconScanQr,
        },
        {
            code: PaymentMethodEnum.CREDIT_CARD,
            label: 'บัตรเครดิต/เดบิต',
            iconDetail: [IconVisa, IconMasterCard, IconBank],
            icon: IconCreditCard,
        },
    ];

    const creditOption = [
        {
            code: PaymentMethodEnum.WOWBOOST_CREDIT,
            label: 'Wowboost Credit',
            iconDetail: [],
            icon: IconWowBoostCredit,
            price: creditValue || 0,
        },
    ];

    const choiceDefault = isMultipleLinks || creditValue >= orderSummaryPrice ? creditOption[0] : paymentOption[0];
    const [chooseAmountInfo, setChooseAmountInfo] = useState<IPaymentChoice>(choiceDefault);

    useEffect(() => {
        handleProductChoose(choiceDefault);
    }, [creditValue]);

    const handleProductChoose = (choice: IPaymentChoice) => {
        setChooseAmountInfo(choice);
    };

    const setLastedCreditValue = async () => {
        const userCreditRes = await getUserCredit();

        if (200 === userCreditRes.status) {
            setCustomerInfo({
                ...(customerInfo as ICustomerInfo),
                creditValue: userCreditRes.data.creditValue,
            });
        }
    };

    const createTokenSuccessHandler = async (token: string | undefined, data: IOrderInfo) => {
        setApiCheckoutState({ ...apiCheckoutState, isLoading: true });
        paymentProcessModal.handleClickOpen();
        const checkoutInfoRes = resolveClientApiState(await checkout({ ...data }), true);

        if (checkoutInfoRes?.isOk) {
            clearProductInfo();
            setLastedCreditValue();
            paymentProcessModal.handleClose();

            const { authorizeUri } = checkoutInfoRes.data;

            if (!isUndefined(authorizeUri)) {
                window.location.replace(authorizeUri);
                return;
            }
            paymentProcessModal.handleClose();

            if (checkoutInfoRes.data.paySucess) {
                if (checkoutInfoRes.data) {
                    return navigate(`/account/order-history/process`);
                }

                return navigate(`/account/order-detail/${checkoutInfoRes.data.refId}`);
            }

            saveOrderPaymentPromptPay(checkoutInfoRes.data);
            navigate(`/account/order-payment/${checkoutInfoRes.data.refId}`);
        }

        paymentProcessModal.handleClose();
        paymentCreditFailModal.handleClickOpen();
        setLastPurchaseOrderRefId(checkoutInfoRes.data?.purchaseOrderRefId);
        setApiCheckoutState({ ...apiCheckoutState, isLoading: false });
    };

    const createTokenCreditSuccessHandler = async (token: string | undefined, data: IOrderInfo) => {
        setApiCheckoutState({ ...apiCheckoutState, isLoading: true });
        paymentProcessModal.handleClickOpen();
        const checkoutInfoRes = resolveClientApiState(await checkoutCredit({ ...data }), true);

        if (checkoutInfoRes?.isOk) {
            clearProductInfo();

            const { authorizeUri } = checkoutInfoRes.data;

            if (!isUndefined(authorizeUri)) {
                window.location.replace(authorizeUri);
                return;
            }

            paymentProcessModal.handleClose();

            if (checkoutInfoRes.data.paySucess) {
                if (checkoutInfoRes.data) {
                    return navigate(`/account/credit/credit-history`);
                }

                return navigate(`/account/order-detail/${checkoutInfoRes.data.refId}`, {
                    state: {
                        orderType: OrderTypeEnum.CREDIT,
                    },
                });
            }

            saveOrderPaymentPromptPay(checkoutInfoRes.data);
            navigate(`/account/order-payment/${checkoutInfoRes.data.refId}`, {
                state: {
                    orderType: OrderTypeEnum.CREDIT,
                },
            });
        }

        paymentProcessModal.handleClose();
        paymentCreditFailModal.handleClickOpen();
        setLastPurchaseOrderRefId(checkoutInfoRes.data?.purchaseOrderRefId);
        setApiCheckoutState({ ...apiCheckoutState, isLoading: false });
    };

    const onSubmit = () => {
        if (orderType == OrderTypeEnum.CREDIT) {
            const orderCredit: IOrderInfo = {
                paymentMethod: chooseAmountInfo.code,
                data: orderCreditInfo.data,
                customerInfo: orderCreditInfo.customerInfo,
            };

            createTokenCreditSuccessHandler(undefined, orderCredit);
            return;
        }

        const order: IOrderInfo = {
            paymentMethod: chooseAmountInfo.code,
            data: orderInfo.data,
            customerInfo: orderInfo.customerInfo,
        };
        createTokenSuccessHandler(undefined, order);
    };

    return (
        <Layout contentAvoidHeader contentBg={bgImage}>
            <MetaSeo title={trans('meta_seo.history.title')} description={trans('meta_seo.history.description')} />
            <OrderCardLayout title={trans('credit.payment_method')}>
                {orderType === OrderTypeEnum.PRODUCT && (
                    <>
                        <PaymentGroup
                            choices={creditOption}
                            onChoose={(choice) => handleProductChoose(choice)}
                            choiceSelected={chooseAmountInfo}
                            choiceType={'creditOption'}
                        />
                        <Stack style={{ paddingLeft: '30%', paddingRight: '30%' }}>
                            <PlainButton
                                size="small"
                                color={'secondary'}
                                label={trans('credit.title')}
                                onClick={() => {
                                    navigate('/account/credit/amount');
                                }}
                            />
                        </Stack>
                    </>
                )}

                {!isMultipleLinks && (
                    <PaymentGroup
                        choices={paymentOption}
                        onChoose={(choice) => handleProductChoose(choice)}
                        choiceSelected={chooseAmountInfo}
                        choiceType={'paymentOption'}
                    />
                )}

                <Stack marginTop={isMultipleLinks ? 3 : 0} spacing={1.5}>
                    <OrderSummary price={orderSummaryPrice} />
                    <Box className={'-btn-refund-policy-wrapper'}>
                        <TextWithLink
                            title={trans('order_summary.refund_policy.title')}
                            linkTitle={trans('order_summary.refund_policy.button_text')}
                            linkOnClick={refundPolicyModal.handleClickOpen}
                        />
                        <TextWithLink title={trans('order_summary.refund_policy.description')} />
                    </Box>
                    <Box style={{ paddingLeft: '30%', paddingRight: '30%' }}>
                        <PlainButton
                            fullWidth
                            type={'submit'}
                            color={'primary'}
                            label={trans('credit.button.confirm')}
                            onClick={onSubmit}
                        />
                    </Box>
                </Stack>
            </OrderCardLayout>
            <PaymentProcessModal open={paymentProcessModal.open} handleClose={() => {}} />
            <PaymentCreditFailModal open={paymentCreditFailModal.open} handleClose={paymentCreditFailModal.handleClose} />
            <RefundPolicyModal open={refundPolicyModal.open} handleClose={refundPolicyModal.handleClose} />
        </Layout>
    );
};

export default CreditPayment;
